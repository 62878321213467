
Vue.component('itemsSectionComponent',{
    data: function () {
        return {
            itemsFlag:false,
            orderSelected:null,
            filteredItems: []
        }
    },
    watch: {
        '$route': 'fetchData',
        '$store.state.itemTimeFlag':'fetchData',
        '$store.state.itemUpdate':'fetchData',
        '$store.state.orderBy':'fetchData',
        '$store.state.orderDirection':'fetchData'
    },
    computed: {
        viewmode: function(){
           return this.$store.getters.getItemViewMode;
        },
        initItem:function(){
            return this.$store.getters.getItemPerPage*this.$store.getters.getOffset;
        },
        lastItem:function(){
            return this.$store.getters.getItemPerPage*this.$store.getters.getOffset + this.$store.getters.getItemPerPage;
        },
        moduleObject: function () {
            return {
                'green-text': this.viewmode == 'module'
            }
        },
        listObject: function () {
            return {
                'green-text': this.viewmode == 'list'
            }
        },
        itemToOrdered:function(){
            if(this.$store.getters.getSetting)
                return this.$store.getters.getSetting.ItemOrdered
            return []
        }
    },
    methods: {
        fetchData() {
            if(!this.orderSelected)
                for(let orderField of this.$store.getters.getSetting.ItemOrdered){
                    if(orderField.fields.ByDefault){
                        this.orderSelected = orderField.fields.internalId
                    }
                }

            this.itemFlag = !this.itemFlag;
            let newitems =this.$store.getters.getItemsVisibles;
            let itemWithPrices = []
            for(let item of newitems) {
                    let storeItem = this.$store.getters.getItemByCode(item.Code);
                    if (!storeItem.isCalculate)
                        itemWithPrices.push(storeItem);
            }
            if(itemWithPrices.length>0) {
                this.$store.commit('updateAppState',"load items");
                this.$store.dispatch('updatePrices', itemWithPrices);
            }
            this.filteredItems=this.$store.getters.getItemsVisibles;
        },
        loadItems: function (category = null, offset = 0, items_per_page = 25) {
            this.$store.dispatch('loadItems', category, offset, items_per_page);
        },
        searchCategory: function (category) {
            function searchTree(element, matchingCode) {
                if (element.code == matchingCode) {
                    return element;
                } else if (element && element.children != null) {
                    var result = null;
                    for (let i = 0; result == null && i < element.children.length; i++) {
                        result = searchTree(element.children[i], matchingCode);
                    }
                    return result;
                }
                return null;
            }
            if (category) {
                this.loadItems(category.code);
                let cattree = this.$store.getters.getCategories;
                let node = searchTree(cattree[0], category.code);
                this.$store.dispatch('updateCategory', node);
            } else
                this.loadItems(null);
        },
        toogleFavorite: function (item) {
            if ($('#favorite-item-' + item.internalId).prop('textContent') != 'favorite')
                $('#favorite-item-' + item.internalId).prop('textContent', 'favorite');
            else
                $('#favorite-item-' + item.internalId).prop('textContent', 'favorite_border');
            this.$store.dispatch('addItemToFavorite', item)
        },
        reorderItems: function () {
            console.log(this.orderSelected);
            this.$store.dispatch('reorderItems',this.orderSelected);
            this.fetchData();
        },
        changeViewMode:function(viewMode){
            this.$store.dispatch('changeItemViewMode',viewMode)
        }
    },
    created:function () {
        this.fetchData();
    },
    template: `
        <div class="header-produktliste">
            <div class="breadcrumb-container">
                <router-link class="breadcrumb" href="#" :to="'/home'"></i>{{tr('Home')}}</router-link>
                <template v-for="cat in $store.getters.getCategoriesHistory">
                    <router-link :key="'cat-link-'+cat.name" class="breadcrumb" :to="'/category/'+encodeURI(cat.name.replace('/',''))">{{cat.name}}</router-link>
                </template>
            </div>
            <div class="items-options">
                <div class="order-options">
                    <span>{{tr('Order by')}}</span>
                    <select id="soflow" v-model="orderSelected" class="minimal browser-default" @change="reorderItems">
                        <option disabled value="">{{tr('Select One')}}</option>
                        <template v-for="orderField of itemToOrdered">
                            <option :value="orderField.fields.internalId" :selected="orderField.fields.internalId==orderSelected">
                                <p>{{tr(orderField.fields.ItemField)}} ({{orderField.fields.OrderDirection}}) 
                                    <template v-if="orderField.fields.OrderDirection=='asc'">
                                       <h5> &uarr;</h5>
                                    </template>
                                    <template v-else>
                                       <h5> &darr;</h5>
                                    </template>
                                </p>     
                            </option>
                        </template>
                    </select>
                </div>
                <div class="view-options" v-if="$store.getters.getAppState=='ready'">
                    <span @click="changeViewMode('module')" v-bind:class="moduleObject">
                        <span>Mosaico</span>
                        <i class="fas fa-th-large fa-1x"></i>
                    </span>
                    <span @click="changeViewMode('list')" v-bind:class="listObject">
                        <span >Lista</span>
                        <i class="fas fa-list-alt fa-1x"></i>
                    </span>
                </div>
                <template v-if="filteredItems.length>0">
                    <paginationComponent :items="filteredItems" v-if="filteredItems.length>0"></paginationComponent>
                </template>
            </div>
            
            <div class="produktliste">
                <template v-if="$store.getters.getAppState=='load items'">
                    <div class="container center section white" >
                        <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style="width: 100%"></div>  
                        <h3>{{tr('Loads Items')}}</h3>
                    </div>
                </template>
                <template v-else>
                    <template if="filteredItems.length>0">
                        <template v-for="item in filteredItems.slice(initItem,lastItem)">
                            <template v-if="item.visible">
                                <div :key="'itemContainer-'+item.Code" :class="{col:true, 's12': viewmode=='list', 's6 m6 l3': viewmode=='module'}" >
                                  <template v-if="viewmode=='list'">
                                      <itemViewListComponent :item="item.fields? item.fields : item" ></itemViewListComponent>
                                  </template>
                                  <template v-else>
                                      <itemViewModuleComponent :item="item.fields? item.fields : item" ></itemViewModuleComponent>
                                  </template>
                                </div>
                            </template>
                        </template>
                        <template v-if="$store.getters.getAppState=='ready'">
                            <template v-if="filteredItems.length==0">
                                <div class="container center section white" >
                                    <h3>{{tr('Not items for current filters')}}</h3>
                                </div>
                            </template>
                        </template>
                    </template>
                </template>
                
            </div>
            <template v-if="filteredItems.length>0">
                <paginationComponent :items="filteredItems" v-if="filteredItems.length>0"></paginationComponent>
            </template>
            <div class="col s12">
                <span class="detalle" ><strong>Referencias de Empaque: </strong><br>
                EA: Unidad - PK: Pack - BX: Caja - RM: Resma - TB: Tubo - ST: Set - BL: Blister</span>
            </div>
        </div>`
});
